@import "farfisa-theme-variables.scss";

@import "typography.scss";

/* You can add global styles to this file, and also import other style files */

/* Flexbox */

.flex {
    display: flex;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.center {
    align-items: center;
}

.space-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.gap-8 {
    gap: 8px;
}

.gap-16 {
    gap: 16px;
}

.gap-24 {
    gap: 24px;
}

.gap-32 {
    gap: 32px;
}

/* Icons common class */

.small-icon {
    width: 22px;
    height: 22px;
}

.medium-icon {
    width: 28px;
    height: 28px;
}

.large-icon {
    width: 32px;
    height: 32px;
}

.fill-accent {
    fill: $base-accent;
}

/* Spacing */

.top-space {
    margin-top: 16px !important;
}

.large-top-space {
    margin-top: 32px !important;
}

.bottom-space {
    margin-bottom: 16px !important;
}

.large-bottom-space {
    margin-bottom: 32px !important;
}

/* Dimensions */

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

/* Other stuff */

.animated {
    transition: 0.3s !important;
    -o-transition: 0.3s !important;
    -webkit-transition: 0.3s !important;
    -moz-transition: 0.3s !important;
}

.no-select {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.action-icon {
    display: flex;
    fill: $base-accent;
    width: 18px;
    height: 18px;
    margin-left: auto;
    transform: rotate(-90deg);
}

// common dialog classes

.dialog-vertial-layout {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    overflow-y: auto;

    .full-width {
        width: 100%;
    }

    .horizontal-flex-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .row-with-label {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .label {
            margin-top: 4px;
        }
    }

    .label {
        font-size: small;
        color: $neutral-hover;
    }

    .dialog-action-bar {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 16px;
        justify-content: flex-end;
    }

    .warning-icon {
        width: 32px;
        height: 32px;
        fill: $semantic-red;
        display: flex;
    }
}

.dialog-spinner-container {
    overflow-y: auto;
}

// common page classes

.page {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .two-column-layout {
        display: flex;
        gap: 32px;

        .left-column,
        .right-column {
            flex: 1 1 50%;
            display: flex;
            flex-direction: column;
            gap: 32px;
        }
    }
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    #header-icon {
        fill: $base-accent;
        width: 24px;
        height: 24px;
    }

    #header-title {
        color: $base-accent;
        font-size: 20px;
    }
}

@mixin editDeviceConfigurationStyle {
    .row-title {
        font-size: 18px;
        color: $base-accent;
    }

    .cell {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .cell-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;

            #key-icon {
                fill: $base-accent;
            }
        }
    }

    .title {
        font-size: 16px;
        color: $base-accent;
    }

    .no-data-message {
        margin-top: 10px;
        color: $base-accent;
    }
}
