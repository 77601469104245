@import "farfisa-theme-variables.scss";

/* You can add global styles to this file, and also import other style files */

.text-xs {
    font-size: 12px;
    line-height: 14px;
}

.text-xs {
    font-size: 12px;
    line-height: 14px;
}

.text-m {
    font-size: 14px;
    line-height: 16px;
}

.text-l {
    font-size: 16px;
    line-height: 18px;
}

.text-xl {
    font-size: 18px;
    line-height: 20px;
}

.headline-small {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    color: #333333;
}

.headline {
    font-size: 32px;
    line-height: 34px;
    font-weight: 500;
}

// Colors

.text-black {
    color: #000000;
}

.text-gray {
    color: $on-grey;
}

.text-accent {
    color: $base-accent
}