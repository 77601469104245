$base-accent: #304d65;
$base-text-color: #404040;
$base-bg: #ffffff;
$base-border-color: #ddd;
$base-border-radius: 16px;
$yellow-accent: #dba73b;


$neutral-dark-grey: #333333;
$neutral-default: #666666;
$neutral-hover: #999999;
$neutral-pressed: #cccccc;


$on-white: #ffffff;
$on-grey: #8B9FAE;
$on-medium-blue: #506D81;
$on-dark-blue: #304D65;


$background-white: #ffffff;
$background-light-blue: #F4F4F6;
$background-light-grey: #f3f4f6;
$background-dark-blue: #304D65;


$semantic-red: #EC3A3A;
$semantic-yellow: #EA8D01;
$semantic-green: #7BD26C;


$area-placeholder-color: #dcdde3;